<template>
  <el-container
    v-loading.fullscreen.lock="!$auth.ready() || $root.loading"
    class="is-vertical main"
  >
    <top-nav
      v-if="
        ['404', '403'].indexOf(this.$route.name) === -1 &&
        $auth.ready() &&
        $auth.check()
      "
    ></top-nav>
    <el-main :style="containerStyles">
      <template v-if="$auth.ready()">
        <transition name="fade" mode="out-in" appear>
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </template>
    </el-main>
    <el-footer
      v-if="
        ['404', '403'].indexOf(this.$route.name) === -1 &&
        $auth.ready() &&
        $auth.check() &&
        !isMobileApp
      "
    >
      <el-row>
        <el-col
          style="
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <p style="margin: 0; text-align: center">
            Copyright 2017 - {{ dateTimeNow() }} {{ app.name }}<br />
            <small
              >All other product names, logos, and brands are property of their
              respective owners.
            </small>
          </p>
        </el-col>
      </el-row>
    </el-footer>

    <modals-container />

    <modal
      name="create-support-ticket"
      adaptive
      height="auto"
      scrollable
      width="1000"
    >
      <support-ticket-create-form></support-ticket-create-form>
    </modal>

    <resize-observer @notify="handleResize" />
  </el-container>
</template>

<style lang="scss">
.main {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fafafa;
  box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.1);
}

footer {
  background-color: rgba(255, 255, 255, 0.9);
  height: auto;
  font-size: 1rem;
  line-height: 1.2rem;
}

@media only screen and (max-width: 560px) {
  footer {
    padding: 0 !important;
    p {
      font-size: 0.8rem !important;
      line-height: 0.9rem !important;
    }
  }
}
</style>

<style>
</style>

<script>
import TopNav from "@/components/TopNav"
import DateTime from "@/mixins/DateTime"
import Whitelabel from "@/mixins/Whitelabel"
import SupportTicketCreateForm from "@/components/Forms/SupportTicketCreateForm"
import { mapActions, mapGetters } from "vuex"
import { Message } from "element-ui"

export default {
  name: "App",

  mixins: [DateTime, Whitelabel],
  components: {
    TopNav,
    SupportTicketCreateForm,
  },

  data() {
    return {
      loading: true,
      prevRoute: null,
      windowWidth: 0,
      app: {
        name: process.env.VUE_APP_APP_NAME,
        label: process.env.VUE_APP_APP_LABEL,
        primaryColor: process.env.VUE_APP_APP_PRIMARY_COLOR,
      },
    }
  },
  computed: {
    ...mapGetters({
      companies: "companies/list",
      isMobileApp: "auth/isMobileApp",
    }),
    containerStyles() {
      if (
        !this.$auth.check() ||
        ["login", "404"].indexOf(this.$route.name) !== -1
      ) {
        return {
          display: "flex",
        }
      } else {
        return {}
      }
    },
  },
  watch: {
    "$auth.watch.authenticated": {
      immediate: true,
      handler: function (newVal) {
        if (newVal) this.setUser(this.$auth.user())
      },
    },
    $route(to) {
      if (to.params.company) {
        this.setCompany(to.params.company)
        this.destroyExistingStyles()
        this.applyStyles()
      } else {
        this.setCompany(null)
        this.destroyExistingStyles()
      }
    },
  },
  methods: {
    ...mapActions({
      setUser: "auth/set",
      setCompany: "companies/set",
      refresh: "companies/refresh",
      updateIsMobileApp: "auth/updateIsMobileApp",
    }),
    globalRequestError(error) {
      if (error.response) {
        if (process.env.NODE_ENV === "development") {
          Message({
            message: error.response.status + ": " + error.response.data.message,
            type: "error",
          })
          throw error
        } else {
          if (error.response.status !== 422) {
            Message({
              message:
                "We could not complete your request. Please try again and contact support if the issue persists.",
              type: "error",
            })
          }
        }
      } else if (error.request) {
        if (process.env.NODE_ENV === "development") {
          Message({
            message: "Timeout.",
            type: "error",
          })
          throw error
        } else {
          Message({
            message:
              "We could not complete your request. Please try again and contact support if the issue persists.",
            type: "error",
          })
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          Message({
            message: "JS Error: " + error.message,
            type: "error",
          })
          throw error
        } else {
          Message({
            message:
              "We could not complete your request. Please try again and contact support if the issue persists.",
            type: "error",
          })
        }
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth - 1
    },
    routeChange() {
      let to = this.$route
      if (["company", "month"].indexOf(to.name) !== -1) {
        this.setCompany(null)
        this.setCompany(to.params.company)
        this.destroyExistingStyles()
        this.applyStyles()
      } else {
        this.setCompany(null)
        this.destroyExistingStyles()
      }
    },
  },

  created() {
    this.windowWidth = window.innerWidth - 1
    this.refresh({ page: 1, query: '' });

    let params = new URLSearchParams(document.location.search);
    if (params.get("isMobileApp")) {
      const isMobileApp = params.get("isMobileApp") === 'true';
      this.updateIsMobileApp(isMobileApp);
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>
